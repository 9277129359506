import { useEffect, useState, createContext, useContext } from "react";
import { getPrivateCategoriesRequest, createCategoryRequest, deleteCategoryRequest, updateNameCategoryRequest } from '../api/category.js'
import { useProducts } from "./ProductContext.js";

const CategoryContext = createContext()

export const useCategories = () => {
    const context = useContext(CategoryContext)
    if(!context) throw new Error("useCategories must be used within an TaskProvider")

    return context
}

export const CategoryProvider = ({children}) => {
    const { updateCategoryNameInProducts } = useProducts()
    const [categories, setCategories] = useState([])
    const [loadingCategories, setLoadingCategories] = useState(true)
    const [createCategoryErrors, setCreateCategoryErrors] = useState([])
    const [deleteCategoryErrors, setDeleteCategoryErrors] = useState([])
    const [updateCategoryErrors, setUpdateCategoryErrors] = useState([])
    const [loadingCreateCategory, setLoadingCreateCategory] = useState(false)
    const [loadingDeleteCategory, setLoadingDeleteCategory] = useState(false)
    const [loadingUpdateCategory, setLoadingUpdateCategory] = useState(false)

    useEffect(() => {
        if(createCategoryErrors.length > 0 || createCategoryErrors.message) {
            const timer = setTimeout(() => {
                setCreateCategoryErrors([])
            }, 5000)
            return () => clearTimeout(timer)
        }
    }, [createCategoryErrors])

    useEffect(() => {
        if(deleteCategoryErrors.length > 0 || deleteCategoryErrors.message) {
            const timer = setTimeout(() => {
                setDeleteCategoryErrors([])
            }, 5000)
            return () => clearTimeout(timer)
        }
    }, [deleteCategoryErrors])

    useEffect(() => {
        if(updateCategoryErrors.length > 0 || updateCategoryErrors.message) {
            const timer = setTimeout(() => {
                setUpdateCategoryErrors([])
            }, 5000)
            return () => clearTimeout(timer)
        }
    }, [updateCategoryErrors])

    const ordenarLista = (categories) => {
        const sortedList = [...categories].sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 0))
        setCategories(sortedList)
    }

    const getCategories = async () => {
        try {
            const res = await getPrivateCategoriesRequest()
            const categories = res.data
            ordenarLista(categories)
            setLoadingCategories(false)
        } catch(error) {
            console.error(error)
        }
    }

    const createCategory = async (category) => {
        setLoadingCreateCategory(true)
        try {
            const res = await createCategoryRequest(category)
            const newCategories = [...categories, res.data]
            ordenarLista(newCategories)
            setLoadingCreateCategory(false)
            return true
        } catch(error) {
            if(error.code === "ERR_NETWORK") {
                setCreateCategoryErrors([{message: error.message}])
            } else {
                setCreateCategoryErrors(error.response.data)
            }
            setLoadingCreateCategory(false)
            return false
        }
    }

    const deleteCategory = async (name) => {
        setLoadingDeleteCategory(true)
        try {
            const res = await deleteCategoryRequest(name)
            const categoriesUpdated = categories.filter(category => category.name != name)
            ordenarLista(categoriesUpdated)
            setLoadingDeleteCategory(false)
            return true
        } catch(error) {
            if(error.code === "ERR_NETWORK") {
                setDeleteCategoryErrors([{message: error.message}])
            } else {
                setDeleteCategoryErrors(error.response.data)
            }
            setLoadingDeleteCategory(false)
            return false
        }
    }

    const updateNameCategory = async (name, newName) => {
        setLoadingUpdateCategory(true)
        try {
            const res = await updateNameCategoryRequest(name, newName)
            const categoriesUpdated = categories.map(category => {
                if(category.name === name) return category = res.data
                return category
            })
            updateCategoryNameInProducts(name, newName.name)
            ordenarLista(categoriesUpdated)
            setLoadingUpdateCategory(false)
            return true
        } catch(error) {
            if(error.code === "ERR_NETWORK") {
                setUpdateCategoryErrors([{message: error.message}])
            } else {
                setUpdateCategoryErrors(error.response.data)
            }
            setLoadingUpdateCategory(false)
            return false
        }
    }

    return(
        <CategoryContext.Provider value={{categories, loadingCategories, createCategoryErrors, deleteCategoryErrors, updateCategoryErrors, loadingCreateCategory, loadingDeleteCategory, loadingUpdateCategory, getCategories, createCategory, deleteCategory, updateNameCategory, setCreateCategoryErrors, setDeleteCategoryErrors, setUpdateCategoryErrors}}>
            {children}
        </CategoryContext.Provider>
    )
}