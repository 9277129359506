import './css/HomePage.css'
import { useEffect, useState } from "react"
import { useForm } from 'react-hook-form'
import { Spinner } from 'reactstrap';
import { useClients } from "../context/ClientContext.js"
import { useProducts } from "../context/ProductContext.js"
import { useCategories } from "../context/CategoryContext.js"
import Navbar from "../Components/Navbar/Navbar.js"
import Modal from '../Components/Modal/Modal.js'
import Category from "../Components/Category/Category.js"
import Footer from "../Components/Footer/Footer.js"
import Item from "../Components/Item/Item.js"
import { MdOutlineSmartphone } from "react-icons/md";
import { BsQrCode } from "react-icons/bs";
import QRCode from "qrcode.react";
import { format } from 'date-fns';
import { es } from 'date-fns/locale';

const HomePage = () => {
    const [loadingCliente, setLoadingCliente] = useState(true)
    const {getProfile} = useClients()
    const [cliente, setCliente] = useState(null)
    const {getProducts, products, loadingProducts, createProduct, createProductErrors, setCreateProductErrors, loadingCreateProduct} = useProducts()
    const {getCategories, categories, loadingCategories, createCategory, createCategoryErrors, setCreateCategoryErrors, loadingCreateCategory} = useCategories()
    const {register, handleSubmit, formState: {errors}, reset} = useForm()
    const {register: register1, handleSubmit: handleSubmit1, formState: {errors: errors1}, reset: reset1} = useForm()
    const [filtroActivo, setFiltroActivo] = useState("name")
    const [valueFiltro, setValueFiltro] = useState("")
    const [listProducts, setListProducts] = useState([])
    const [showAdd, setShowAdd] = useState(false)
    const [showAddCategory, setShowAddCategory] = useState(false)

    useEffect(() => {
        const obtenerPerfil = async () => {
            setLoadingCliente(true)
            const perfil = await getProfile()
            setCliente(perfil)
            setLoadingCliente(false)
            await getCategories()
            await getProducts(perfil.username)
        }

        obtenerPerfil()
    }, [])

    useEffect(() => {
        setListProducts(products)
    }, [products])

    const changeFiltro = (e) => {
        setFiltroActivo(e.target.value)
        setListProducts(products)
        setValueFiltro("")
    }

    const changeBuscar = (e) => {
        const busqueda = e.target.value.toLowerCase()
        setValueFiltro(e.target.value)
        if(e.target.value === '') {
            setListProducts(products)
        } else if(filtroActivo === 'name') {
            setListProducts(products.filter(product => product.name.toLowerCase().includes(busqueda)))
        } else {
            setListProducts(products.filter(product => product.category_name.toLowerCase().includes(busqueda)))
        }
    }

    const abrirModalAgregar = () => setShowAdd(true)

    const cerrarModalAgregar = () => {
        setShowAdd(false)
        reset()
        setCreateProductErrors([])
    }

    const agregarProducto = handleSubmit(async (values) => {
        values.price = Number(values.price)

        if(await createProduct(values)) {
            cerrarModalAgregar()
        }
    })

    const abrirModalAgregarCategoría = () => setShowAddCategory(true)

    const cerrarModalAgregarCategoría = () => {
        setShowAddCategory(false)
        reset1()
        setCreateCategoryErrors([])
    }

    const agregarCategoría = handleSubmit1(async (values) => {
        if (values.name.trim() === '') {
            setCreateCategoryErrors([{message: 'El nombre no puede ser solo espacios en blanco'}]);
            return;
        }
        if(await createCategory(values)) {
            cerrarModalAgregarCategoría()
        }
    })

    const downloadQRCode = (e) => {
        e.preventDefault()
        const canvas = document.getElementById("qr-gen");
        const pngUrl = canvas
          .toDataURL("image/png")
          .replace("image/png", "image/octet-stream");
        let downloadLink = document.createElement("a");
        downloadLink.href = pngUrl;
        downloadLink.download = `qr-${cliente.username}.png`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };

    const redirigirVerCarta = () => {
        window.location.href = `https://pido-online.com.ar/${!loadingCliente ? cliente.username : ''}`
    }

    return (
        <div className="home-page">
            <Navbar />
            <div className='clients-page'>
                <div className="container mb-3">
                    <div className="row">
                        <div className='box box-categories'>
                            <div className='box-categories-title'>
                                <h1>Tu perfil</h1>
                            </div>
                            <div className='box-perfil-table'>
                                <hr/>
                                <div className='row'>
                                    {loadingCliente
                                    ? <div className='col-12 box-clients-table-users'><Spinner /></div>
                                    : <>
                                    <div className='box-perfil-item-pago'>
                                        {cliente.active
                                        ? <div className='box-perfil-item-pago-active'></div>
                                        : <div className='box-perfil-item-pago-inactive'></div>
                                        }
                                        <div className='box-perfil-item-pago-info'>
                                            {cliente.expiration === null
                                            ? <>
                                                <span>{cliente.active ? '¡Estás al día!' : 'No tienes plan vigente'}</span>
                                                <p>Sin vencimiento</p>
                                            </>
                                            : <>
                                                <span>{cliente.active ? '¡Estás al día!' : 'Tu plan ha vencido'}</span>
                                                <p>{new Date() < new Date(cliente.expiration) ? 'Próximo vencimiento: ' : 'Venció el '}
                                                {format(new Date(cliente.expiration), "dd 'de' MMMM 'de' yyyy 'a las' HH:mm'hs'", { locale: es })}</p>
                                            </>
                                            }
                                        </div>
                                    </div>
                                    <div className='box-perfil-items'>
                                        <div className='box-perfil-item' onClick={redirigirVerCarta}>
                                            <MdOutlineSmartphone />
                                            <span>Ver menú</span>
                                        </div>
                                        <div className='box-perfil-item' onClick={downloadQRCode}>
                                            <BsQrCode />
                                            <span className='ms-1'>Descargar QR</span>
                                            <QRCode
                                                id="qr-gen"
                                                value={`https://pido-online.com.ar/${!loadingCliente ? cliente.username : ''}`}
                                                size={500}
                                                level={"H"}
                                                includeMargin={true}
                                            />
                                        </div>
                                    </div>
                                    </>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container mb-3">
                    <div className="row">
                        <div className='box box-categories'>
                            <div className='box-categories-title'>
                                <h1>Categorías</h1>
                                <div className='crear col-12 col-md-3'>
                                    <button onClick={abrirModalAgregarCategoría}>Crear categoría</button>
                                </div>
                            </div>
                            <div className='box-categories-table'>
                                <hr/>
                                <div className='row'>
                                    {loadingCategories
                                    ? <div className='col-12 box-clients-table-users'><Spinner /></div>
                                    : categories.length === 0
                                        ? <div className='col-12 box-clients-table-users'>No tienes categorías</div>
                                        : categories.map((category, index) => (
                                            <Category category={category} key={category.id} index={index} />
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className='box box-filtros'>
                            <div className='filtros col-12 col-md-5'>
                                <label>Filtros</label>
                                <select defaultValue="name" className='col' onChange={changeFiltro}>
                                    <option value="name">Nombre de producto</option>
                                    <option value="category_name">Categoría</option>
                                </select>
                            </div>
                            <div className='buscar col-12 col-md-5'>
                                <label>Buscar</label>
                                <input onChange={changeBuscar} value={valueFiltro} className='col' type='text' placeholder='Buscar...'></input>
                            </div>
                            <div className='crear col-12 col-md-2'>
                                <button onClick={abrirModalAgregar}>Crear producto</button>
                            </div>
                        </div>
                        <div className='box box-clients'>
                            <div className='box-clients-title'>Productos</div>
                            <div className='box-clients-table'>
                                <hr/>
                                <div className='row'>
                                    {loadingProducts || loadingCategories
                                    ? <div className='col-12 box-clients-table-users'><Spinner /></div>
                                    : listProducts.length === 0
                                        ? <div className='col-12 box-clients-table-users'>No tienes productos</div>
                                        : listProducts.map((product, index) => (
                                            <Item product={product} key={product.id} index={index} categories={categories} />
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={showAdd} setShow={cerrarModalAgregar}>
                <div className='client-modal-children'>
                    <div className='client-modal-title fw-bold'>Nuevo producto</div>
                    <form className='client-modal-inputs' onSubmit={agregarProducto}>
                        <div className='col-12 client-modal-inputs-row'>
                            <label className='col-3 text-center'>Nombre</label>
                            <input 
                                className='col-9' type='text' placeholder='Nombre del producto...'
                                maxLength={50}
                                {...register('name', {required: true})} 
                            />
                        </div>
                        {errors.name && <p className='client-modal-inputs-errors'>El nombre es requerido</p>}
                        <div className='col-12 client-modal-inputs-row'>
                            <label className='col-3 text-center'>Descripción</label>
                            <textarea 
                                className='col-9 input-area' type='text' placeholder='Descripción del producto...'
                                maxLength={250}
                                {...register('description')}
                            />
                        </div>
                        {errors.description && <p className='client-modal-inputs-errors'>La descripción es requerida</p>}
                        <div className='col-12 client-modal-inputs-row'>
                            <label className='col-3 text-center'>Precio</label>
                            <input 
                                className='col-9' type='number' placeholder='Precio del producto...'
                                {...register('price', {required: true, min: 0})}
                            />
                        </div>
                        {errors.price && errors.price.type === 'required' && <p className='client-modal-inputs-errors'>El precio es requerido</p>}
                        {errors.price && errors.price.type === 'min' && <p className='client-modal-inputs-errors'>El precio debe ser mayor o igual a 0</p>}
                        <div className='col-12 client-modal-inputs-row'>
                            <label className='col-3 text-center'>Categoría</label>
                            <select 
                                defaultValue="" 
                                className='col-9' 
                                {...register('category_name', { required: true })}
                            >
                                <option value="" disabled>Selecciona una categoría</option>
                                {categories.map(category => (
                                    <option key={category.id} value={category.name}>
                                        {category.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        {errors.category_name && <p className='client-modal-inputs-errors'>La categoría es requerida</p>}
                        {createProductErrors.length > 0 && <div className='login-page-errors'>
                            {createProductErrors.map((error, i) => (
                                <div key={i}>{error.message}</div>
                            ))}
                            </div>
                        }
                        {createProductErrors.message && <div className='login-page-errors'>
                            <div>{createProductErrors.message}</div>
                        </div>
                        }
                        {loadingCreateProduct && <div className='client-modal-inputs-row'>
                                <Spinner />
                            </div>
                        }
                        <div className='client-modal-buttons'>
                            <button type='submit' onClick={agregarProducto} className='client-modal-buttons-blue'>Agregar</button>
                            <button type='reset' onClick={cerrarModalAgregar} className='client-modal-buttons-red'>Cancelar</button>
                        </div>
                    </form>
                </div>
            </Modal>
            <Modal show={showAddCategory} setShow={cerrarModalAgregarCategoría}>
                <div className='client-modal-children'>
                    <div className='client-modal-title fw-bold'>Nueva categoría</div>
                    <form className='client-modal-inputs' onSubmit={agregarCategoría}>
                        <div className='col-12 client-modal-inputs-row'>
                            <label className='col-3 text-center'>Nombre</label>
                            <input 
                                className='col-9' type='text' placeholder='Nombre de la categoría...'
                                maxLength={20}
                                {...register1('name', {required: true})} 
                            />
                        </div>
                        {errors1.name && <p className='client-modal-inputs-errors'>El nombre es requerido</p>}
                        {createCategoryErrors.length > 0 && <div className='login-page-errors'>
                            {createCategoryErrors.map((error, i) => (
                                <div key={i}>{error.message}</div>
                            ))}
                            </div>
                        }
                        {createCategoryErrors.message && <div className='login-page-errors'>
                            <div>{createCategoryErrors.message}</div>
                        </div>
                        }
                        {loadingCreateCategory && <div className='client-modal-inputs-row'>
                                <Spinner />
                            </div>
                        }
                        <div className='client-modal-buttons'>
                            <button type='submit' onClick={agregarCategoría} className='client-modal-buttons-blue'>Agregar</button>
                            <button type='reset' onClick={cerrarModalAgregarCategoría} className='client-modal-buttons-red'>Cancelar</button>
                        </div>
                    </form>
                </div>
            </Modal>
            <Footer />
        </div>
    )
}

export default HomePage