import './Item.css';
import '../Product/Product.css';
import { useState, useEffect, useRef } from 'react';
import { useClients } from '../../context/ClientContext';
import { MdEdit, MdDelete } from "react-icons/md";

import { useForm } from 'react-hook-form'
import { Spinner } from 'reactstrap';
import { useProducts } from '../../context/ProductContext.js'
import Modal from '../Modal/Modal.js'
import { MdCloudUpload, MdCloudDone } from "react-icons/md";

const Item = ({product, index, categories}) => {
    const [mostrarTodo, setMostrarTodo] = useState(false)
    const [descriptionLines, setDescriptionLines] = useState(0)
    const [descriptionHeight, setDescriptionHeight] = useState(null)
    const [imageLoaded, setImageLoaded] = useState(false)
    const descriptionRef = useRef(null)
    const descriptionRefInner = useRef(null)
    const {client} = useClients()

    useEffect(() => {
        if (descriptionRef.current && descriptionRefInner.current) {
            const height = descriptionRefInner.current.clientHeight
            const scrollHeight = descriptionRefInner.current.scrollHeight
            const elementHeight = scrollHeight > height ? scrollHeight : height
            const maxHeight = 32;

            if (elementHeight > maxHeight) {
                setDescriptionLines(Math.ceil(elementHeight / 16));
            }
            
            setDescriptionHeight((mostrarTodo || !(scrollHeight > 32)) ? scrollHeight : '32px')
        }
    }, [product.description, mostrarTodo]);  

    const sobrePasaDescripcion = () => descriptionRefInner?.current?.scrollHeight > 32

    const formatterPeso = new Intl.NumberFormat('es-CO', {
        style: 'currency',
        currency: 'COP',
        minimumFractionDigits: 0
      })

    const redirigir = () => {
        if(!product.link == "") {
            window.location.href = product.link;
        }
    }

    // ------------------------------------------------------------------------------------------------
    const {deleteProduct, deleteProductErrors, setDeleteProductErrors, loadingDeleteProduct, updateProductErrors, setUpdateProductErrors, loadingUpdateProduct, updateActiveProduct, updateHasStockProduct, updateNameProduct, updateDescriptionProduct, updatePriceProduct, updateCategoryProduct, loadingUploadImageProduct, uploadImageProductErrors, setUploadImageProductErrors, uploadImageProduct, deleteImageProduct} = useProducts()
    const {register, handleSubmit, formState: {errors}, reset} = useForm()
    const {register: register1, handleSubmit: handleSubmit1, formState: {errors: errors1}, reset: reset1} = useForm()
    const {register: register2, handleSubmit: handleSubmit2, formState: {errors: errors2}, reset: reset2} = useForm()
    const {register: register3, handleSubmit: handleSubmit3, formState: {errors: errors3}, reset: reset3} = useForm()
    const {register: register4, handleSubmit: handleSubmit4, formState: {errors: errors4}, reset: reset4} = useForm()

    const [showEdit, setShowEdit] = useState(false)
    const [showDelete, setShowDelete] = useState(false)
    const [showUpload, setShowUpload] = useState(false)

    const [showEditName, setShowEditName] = useState(false)
    const [disabledButtonEditName, setDisabledButtonEditName] = useState(true)
    const [valueEditName, setValueEditName] = useState(product.name)

    const [showEditDescription, setShowEditDescription] = useState(false)
    const [disabledButtonEditDescription, setDisabledButtonEditDescription] = useState(true)
    const [valueEditDescription, setValueEditDescription] = useState(product.description)

    const [showEditPrice, setShowEditPrice] = useState(false)
    const [disabledButtonEditPrice, setDisabledButtonEditPrice] = useState(true)
    const [valueEditPrice, setValueEditPrice] = useState(product.price)

    const [showEditCategoryName, setShowEditCategoryName] = useState(false)
    const [disabledButtonEditCategoryName, setDisabledButtonEditCategoryName] = useState(true)
    const [valueEditCategoryName, setValueEditCategoryName] = useState(product.category_name)

    const [showEditStock, setShowEditStock] = useState(false)

    const [showEditActive, setShowEditActive] = useState(false)

    const [showDeleteImage, setShowDeleteImage] = useState(false)

    const [showUploadImage, setShowUploadImage] = useState(false)
    const [disabledButtonSubirImagen, setDisabledButtonSubirImagen] = useState(true)
    const [valueUploadImage, setValueUploadImage] = useState(null)

    // ------------------------------------------------------------------------------------------------

    const abrirModalEditar = () => setShowEdit(true)

    const abrirModalBorrar = () => setShowDelete(true)

    const cerrarModalEditar = () => setShowEdit(false)

    const cerrarModalBorrar = () => {
        setShowDelete(false)
        setDeleteProductErrors([])
    }

    const borrarProducto = async () => {
        if(await deleteProduct(product.id)) {
            cerrarModalBorrar()
        }
    }

    const cerrarModalSubida = () => {
        setShowUpload(false)
        setUploadImageProductErrors([])
    }

    // NAME----------------------------------------------------------------------------------------

    const resetForm = () => {
        reset()
        setUpdateProductErrors([])
    }

    const abrirModalEditarName = () => {
        cerrarModalEditar()
        setValueEditName(product.name)
        setShowEditName(true)
    }

    const cerrarModalEditarName = () => {
        setShowEditName(false)
        resetForm()
        setDisabledButtonEditName(true)
    }

    const editarNameProducto = handleSubmit(async (values) => {
        values.name = valueEditName
        if(await updateNameProduct(product.id, values)) {
            cerrarModalEditarName()
        }
    })

    const verificarBotonEditName = (e) => {
        if(e.target.value === product.name) {
            setDisabledButtonEditName(true)
        } else {
            setDisabledButtonEditName(false)
        }
        setValueEditName(e.target.value)
    }

    // DESCRIPTION----------------------------------------------------------------------------------------

    const resetForm1 = () => {
        reset1()
        setUpdateProductErrors([])
    }

    const abrirModalEditarDescription = () => {
        cerrarModalEditar()
        setValueEditDescription(product.description)
        setShowEditDescription(true)
    }

    const cerrarModalEditarDescription = () => {
        setShowEditDescription(false)
        resetForm1()
        setDisabledButtonEditDescription(true)
    }

    const editarDescriptionProducto = handleSubmit1(async (values) => {
        values.description = valueEditDescription
        if(await updateDescriptionProduct(product.id, values)) {
            cerrarModalEditarDescription()
        }
    })

    const verificarBotonEditDescription = (e) => {
        if(e.target.value === product.description) {
            setDisabledButtonEditDescription(true)
        } else {
            setDisabledButtonEditDescription(false)
        }
        setValueEditDescription(e.target.value)
    }

    // PRICE----------------------------------------------------------------------------------------

    const resetForm2 = () => {
        reset2()
        setUpdateProductErrors([])
    }

    const abrirModalEditarPrice = () => {
        cerrarModalEditar()
        setValueEditPrice(product.price)
        setShowEditPrice(true)
    }

    const cerrarModalEditarPrice = () => {
        setShowEditPrice(false)
        resetForm2()
        setDisabledButtonEditPrice(true)
    }

    const editarPriceProducto = handleSubmit2(async (values) => {
        values.price = Number(valueEditPrice)
        if(await updatePriceProduct(product.id, values)) {
            cerrarModalEditarPrice()
        }
    })

    const verificarBotonEditPrice = (e) => {
        if(e.target.value == product.price) {
            setDisabledButtonEditPrice(true)
        } else {
            setDisabledButtonEditPrice(false)
        }
        setValueEditPrice(e.target.value)
    }

    // CATEGORY NAME----------------------------------------------------------------------------------------

    const resetForm3 = () => {
        reset3()
        setUpdateProductErrors([])
    }

    const abrirModalEditarCategoryName = () => {
        cerrarModalEditar()
        setValueEditCategoryName(product.category_name)
        setShowEditCategoryName(true)
    }

    const cerrarModalEditarCategoryName = () => {
        setShowEditCategoryName(false)
        resetForm3()
        setDisabledButtonEditCategoryName(true)
    }

    const editarCategoryNameProducto = handleSubmit3(async (values) => {
        values.category_name = valueEditCategoryName
        if(await updateCategoryProduct(product.id, values)) {
            cerrarModalEditarCategoryName()
        }
    })

    const verificarBotonEditCategoryName = (e) => {
        if(e.target.value == product.category_name) {
            setDisabledButtonEditCategoryName(true)
        } else {
            setDisabledButtonEditCategoryName(false)
        }
        setValueEditCategoryName(e.target.value)
    }

    // STOCK------------------------------------------------------------------------------------------

    const abrirModalEditarStock = () => {
        cerrarModalEditar()
        setShowEditStock(true)
    }

    const cerrarModalEditarStock = () => {
        setShowEditStock(false)
        setUpdateProductErrors([])
    }

    const editarStockProducto = async () => {
        if(await updateHasStockProduct(product.id)) {
            cerrarModalEditarStock()
        }
    }

    // ACTIVE------------------------------------------------------------------------------------------

    const abrirModalEditarActive = () => {
        cerrarModalEditar()
        setShowEditActive(true)
    }

    const cerrarModalEditarActive = () => {
        setShowEditActive(false)
        setUpdateProductErrors([])
    }

    const editarActiveProducto = async () => {
        if(await updateActiveProduct(product.id)) {
            cerrarModalEditarActive()
        }
    }

    // DELETE IMAGE------------------------------------------------------------------------------------------

    const abrirModalBorrarImagen = () => {
        cerrarModalEditar()
        cerrarModalSubida()
        setShowDeleteImage(true)
    }

    const cerrarModalBorrarImagen = () => {
        setShowDeleteImage(false)
        setUploadImageProductErrors([])
    }

    const borrarImagenProducto = async () => {
        if(await deleteImageProduct(product.id)) {
            cerrarModalBorrarImagen()
        }
    }

    // UPLOAD IMAGE----------------------------------------------------------------------------------------

    const resetForm4 = () => {
        reset4()
        setUploadImageProductErrors([])
    }

    const abrirModalSubirImagen = () => {
        cerrarModalEditar()
        cerrarModalSubida()
        setValueUploadImage(null)
        setShowUploadImage(true)
    }

    const cerrarModalSubirImagen = () => {
        setShowUploadImage(false)
        resetForm4()
        setDisabledButtonSubirImagen(true)
        setUploadImageProductErrors([])
    }

    const subirImagenProducto = handleSubmit4(async (values) => {
        const formData = new FormData()
        formData.append('product_image', valueUploadImage)

        if(await uploadImageProduct(product.id, formData)) {
            cerrarModalSubirImagen()
        }
    })

    const verificarBotonUploadImage = (e) => {
        setUploadImageProductErrors([])
        const file = e.target.files[0]

        if(file == null) {
            setDisabledButtonSubirImagen(true)
        } else {
            const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/webp'];
            if (!allowedTypes.includes(file.type)) {
                setUploadImageProductErrors([{message: 'Por favor, sube un archivo de imagen en formato JPEG, JPG, PNG o WEBP.'}])
                e.target.value = ''
                setValueUploadImage(null)
                setDisabledButtonSubirImagen(true)
                return;
            }

            const maxSize = 2 * 1024 * 1024
            if (file.size > maxSize) {
                setUploadImageProductErrors([{message: 'El archivo es demasiado grande. El tamaño máximo permitido es 2MB.'}])
                e.target.value = ''
                setValueUploadImage(null)
                setDisabledButtonSubirImagen(true)
                return;
            }
            setDisabledButtonSubirImagen(false)
        }
        setValueUploadImage(file)
    }

    return (
        <>
        <ul className='col-12 col-lg-6 item-box'>
            <div className={client.color_cuerpo_claro ? 'item' : 'item oscuro'} key={product.index}>
                <div className='product-itemImg-box'>
                    {product.has_image && product.image_url
                    ? <div className='itemImg'>
                        <div className={imageLoaded ? 'itemImg-box' : (client.color_cuerpo_claro ? 'itemImgLoader-box' : 'itemImgLoader-box oscuro')}>
                            <img src={product.image_url} alt={product.name} onLoad={() => setImageLoaded(true)} />
                        </div>
                    </div>
                    : <img src='./images/product-placeholder.svg' alt='imagen placeholder' />
                    }
                </div>
                <div onClick={redirigir} className='itemText'>
                    <div>
                        <div className='mb-1'>
                            {product.active
                            ? <span className='badge badge-success me-1'>
                                Activo
                            </span>
                            : <span className='badge badge-danger me-1'>
                                Inactivo
                            </span>
                            }
                            {product.has_stock
                            ? <span className='badge badge-success'>
                                Con stock
                            </span>
                            : <span className='badge badge-danger'>
                                Sin stock
                            </span>
                            }
                        </div>
                        <li className={client.color_cuerpo_claro ? 'name' : 'name oscuro'}>{product.name}</li>
                        <div
                            ref={descriptionRef}
                            className={`description-box ${mostrarTodo ? 'full' : ''}`}
                            style={{height: descriptionHeight}}
                            onClick={() => sobrePasaDescripcion() ? setMostrarTodo(!mostrarTodo) : ''}
                        >
                            <li className={client.color_cuerpo_claro ? 'description' : 'description oscuro'} ref={descriptionRefInner}>
                                {product.description}
                            </li>
                        </div>
                        {(mostrarTodo || sobrePasaDescripcion()) && (
                            <div 
                                className={client.color_cuerpo_claro ? 'leer' : 'leer oscuro'} 
                                onClick={() => setMostrarTodo(!mostrarTodo)}>
                                {mostrarTodo ? 'Leer menos' : 'Leer más'}
                            </div>
                        )}
                    </div>
                    <div className='precio-box'>
                        <li className={!product.has_stock ? 'precio precio-sinstock' : 'precio'}>
                            {product.has_stock ? formatterPeso.format(product.price) : 'Sin stock'}
                        </li>
                        <MdEdit className='me-1' size='20px' onClick={abrirModalEditar} />
                        <MdDelete size='20px' onClick={abrirModalBorrar} />
                    </div>
                </div>
            </div>
        </ul>
        <Modal
            show={showEdit}
            setShow={setShowEdit}
        >
            <div className='client-modal-children'>
                <div className='client-modal-title fw-bold'>Editar producto <span>{product.name}</span></div>
                <div className='col-12 client-modal-edit'>
                    <button className='col-12' onClick={abrirModalEditarName}>Editar nombre</button>
                    <button className='col-12' onClick={abrirModalEditarDescription}>Editar descripción</button>
                    <button className='col-12' onClick={abrirModalEditarPrice}>Editar precio</button>
                    <button className='col-12' onClick={abrirModalEditarActive}>Pasar de {product.active ? 'activo a inactivo' : 'inactivo a activo'}</button>
                    <button className='col-12' onClick={abrirModalEditarStock}>Pasar de {product.has_stock ? 'disponible a sin stock' : 'sin stock a disponible'}</button>
                    <button className='col-12' onClick={abrirModalEditarCategoryName}>Cambiar de categoría</button>
                    <button className='col-12' onClick={product.has_image ? abrirModalBorrarImagen : abrirModalSubirImagen}>{product.has_image ? 'Eliminar imágen' : 'Asignar imágen'}</button>
                </div>
                <div className='client-modal-buttons'>
                    <button onClick={cerrarModalEditar} className='client-modal-buttons-red'>Cancelar</button>
                </div>
            </div>
        </Modal>
        <Modal
            show={showDelete}
            setShow={cerrarModalBorrar}
        >
            <div className='client-modal-children'>
                <div className='client-modal-title'>Se eliminará el producto <span>{product.name}</span>, ¿estás seguro?</div>
                {deleteProductErrors.length > 0 && <div className='login-page-errors'>
                    {deleteProductErrors.map((error, i) => (
                        <div key={i}>{error.message}</div>
                    ))}
                    </div>
                }
                {deleteProductErrors.message && <div className='login-page-errors'>
                    <div>{deleteProductErrors.message}</div>
                </div>
                }
                {loadingDeleteProduct && <div className='client-modal-inputs-row'>
                        <Spinner />
                    </div>
                }
                <div className='client-modal-buttons'>
                    <button onClick={borrarProducto} className='client-modal-buttons-red'>Eliminar</button>
                    <button onClick={cerrarModalBorrar} className='client-modal-buttons-blue'>Cancelar</button>
                </div>
            </div>
        </Modal>
        <Modal
            show={showEditName}
            setShow={cerrarModalEditarName}
        >
            <div className='client-modal-children'>
                <div className='client-modal-title fw-bold'>Editar nombre de <span>{product.name}</span></div>
                <form className='client-modal-inputs' onSubmit={editarNameProducto}>
                    <div className='col-12 client-modal-inputs-row'>
                        <label className='col-3 text-center'>Nombre</label>
                        <input
                            className='col-9' type='text' placeholder='Nombre de producto...'
                            {...register('name', {required: true})}
                            onChange={verificarBotonEditName}
                            value={valueEditName}
                            maxLength={50}
                        />
                    </div>
                    {errors.name && <p className='client-modal-inputs-errors'>El nombre de producto es requerido</p>}
                    {updateProductErrors.length > 0 && <div className='login-page-errors'>
                        {updateProductErrors.map((error, i) => (
                            <div key={i}>{error.message}</div>
                        ))}
                        </div>
                    }
                    {updateProductErrors.message && <div className='login-page-errors'>
                        <div>{updateProductErrors.message}</div>
                    </div>
                    }
                    {loadingUpdateProduct && <div className='client-modal-inputs-row'>
                            <Spinner />
                        </div>
                    }
                    <div className='client-modal-buttons'>
                        <button type='submit' onClick={editarNameProducto} className='client-modal-buttons-blue' disabled={disabledButtonEditName}>Guardar</button>
                        <button type='reset' onClick={cerrarModalEditarName} className='client-modal-buttons-red'>Cancelar</button>
                    </div>
                </form>
            </div>
        </Modal>
        <Modal
            show={showEditDescription}
            setShow={cerrarModalEditarDescription}
        >
            <div className='client-modal-children'>
                <div className='client-modal-title fw-bold'>Editar descripción de <span>{product.name}</span></div>
                <form className='client-modal-inputs' onSubmit={editarDescriptionProducto}>
                    <div className='col-12 client-modal-inputs-row'>
                        <label className='col-3 text-center'>Descripción</label>
                        <textarea
                            className='col-9 input-area' type='text' placeholder='Descripción de producto...'
                            {...register1('description')}
                            onChange={verificarBotonEditDescription}
                            value={valueEditDescription}
                            maxLength={250}
                        />
                    </div>
                    {errors1.name && <p className='client-modal-inputs-errors'>La descripción de producto es requerida</p>}
                    {updateProductErrors.length > 0 && <div className='login-page-errors'>
                        {updateProductErrors.map((error, i) => (
                            <div key={i}>{error.message}</div>
                        ))}
                        </div>
                    }
                    {updateProductErrors.message && <div className='login-page-errors'>
                        <div>{updateProductErrors.message}</div>
                    </div>
                    }
                    {loadingUpdateProduct && <div className='client-modal-inputs-row'>
                            <Spinner />
                        </div>
                    }
                    <div className='client-modal-buttons'>
                        <button type='submit' onClick={editarDescriptionProducto} className='client-modal-buttons-blue' disabled={disabledButtonEditDescription}>Guardar</button>
                        <button type='reset' onClick={cerrarModalEditarDescription} className='client-modal-buttons-red'>Cancelar</button>
                    </div>
                </form>
            </div>
        </Modal>
        <Modal
            show={showEditPrice}
            setShow={cerrarModalEditarPrice}
        >
            <div className='client-modal-children'>
                <div className='client-modal-title fw-bold'>Editar precio de <span>{product.name}</span></div>
                <form className='client-modal-inputs' onSubmit={editarPriceProducto}>
                    <div className='col-12 client-modal-inputs-row'>
                        <label className='col-3 text-center'>Precio</label>
                        <input
                            className='col-9' type='number' placeholder='Precio de producto...'
                            {...register2('price', {required: true, min: 0})}
                            onChange={verificarBotonEditPrice}
                            value={valueEditPrice}
                        />
                    </div>
                    {errors2.price && errors2.price.type === 'required' && <p className='client-modal-inputs-errors'>El precio de producto es requerido</p>}
                    {errors2.price && errors2.price.type === 'min' && <p className='client-modal-inputs-errors'>El precio de producto debe ser mayor o igual a 0</p>}
                    {updateProductErrors.length > 0 && <div className='login-page-errors'>
                        {updateProductErrors.map((error, i) => (
                            <div key={i}>{error.message}</div>
                        ))}
                        </div>
                    }
                    {updateProductErrors.message && <div className='login-page-errors'>
                        <div>{updateProductErrors.message}</div>
                    </div>
                    }
                    {loadingUpdateProduct && <div className='client-modal-inputs-row'>
                            <Spinner />
                        </div>
                    }
                    <div className='client-modal-buttons'>
                        <button type='submit' onClick={editarPriceProducto} className='client-modal-buttons-blue' disabled={disabledButtonEditPrice}>Guardar</button>
                        <button type='reset' onClick={cerrarModalEditarPrice} className='client-modal-buttons-red'>Cancelar</button>
                    </div>
                </form>
            </div>
        </Modal>
        <Modal
            show={showEditStock}
            setShow={cerrarModalEditarStock}
        >
            <div className='client-modal-children'>
                <div className='client-modal-title'>Se pasará de {product.has_stock ? 'disponible a sin stock' : 'sin stock a disponible'} al producto <span>{product.name}</span>, ¿estás seguro?</div>
                {updateProductErrors.length > 0 && <div className='login-page-errors'>
                    {updateProductErrors.map((error, i) => (
                        <div key={i}>{error.message}</div>
                    ))}
                    </div>
                }
                {updateProductErrors.message && <div className='login-page-errors'>
                    <div>{updateProductErrors.message}</div>
                </div>
                }
                {loadingUpdateProduct && <div className='client-modal-inputs-row'>
                        <Spinner />
                    </div>
                }
                <div className='client-modal-buttons'>
                    <button onClick={editarStockProducto} className='client-modal-buttons-blue'>Confirmar</button>
                    <button onClick={cerrarModalEditarStock} className='client-modal-buttons-red'>Cancelar</button>
                </div>
            </div>
        </Modal>
        <Modal
            show={showEditActive}
            setShow={cerrarModalEditarActive}
        >
            <div className='client-modal-children'>
                <div className='client-modal-title'>Se pasará de {product.active ? 'activo a inactivo' : 'inactivo a activo'} al producto <span>{product.name}</span>, ¿estás seguro?</div>
                {updateProductErrors.length > 0 && <div className='login-page-errors'>
                    {updateProductErrors.map((error, i) => (
                        <div key={i}>{error.message}</div>
                    ))}
                    </div>
                }
                {updateProductErrors.message && <div className='login-page-errors'>
                    <div>{updateProductErrors.message}</div>
                </div>
                }
                {loadingUpdateProduct && <div className='client-modal-inputs-row'>
                        <Spinner />
                    </div>
                }
                <div className='client-modal-buttons'>
                    <button onClick={editarActiveProducto} className='client-modal-buttons-blue'>Confirmar</button>
                    <button onClick={cerrarModalEditarActive} className='client-modal-buttons-red'>Cancelar</button>
                </div>
            </div>
        </Modal>
        <Modal
            show={showEditCategoryName}
            setShow={cerrarModalEditarCategoryName}
        >
            <div className='client-modal-children'>
                <div className='client-modal-title fw-bold'>Editar categoría de <span>{product.name}</span></div>
                <form className='client-modal-inputs' onSubmit={editarCategoryNameProducto}>
                    <div className='col-12 client-modal-inputs-row'>
                        <label className='col-3 text-center'>Categoría</label>
                        <select
                            className='col-9' defaultValue={product.category_name}
                            {...register3('category_name', {required: true})}
                            onChange={verificarBotonEditCategoryName}
                            value={valueEditCategoryName}
                        >
                            {categories.map(category => (
                                <option key={category.id} value={category.name} disabled={category.name === product.category_name}>
                                    {category.name} {category.name === product.category_name && '(actual)'}
                                </option>
                            ))}
                        </select>
                    </div>
                    {errors3.category_name && <p className='client-modal-inputs-errors'>La categoría de producto es requerida</p>}
                    {updateProductErrors.length > 0 && <div className='login-page-errors'>
                        {updateProductErrors.map((error, i) => (
                            <div key={i}>{error.message}</div>
                        ))}
                        </div>
                    }
                    {updateProductErrors.message && <div className='login-page-errors'>
                        <div>{updateProductErrors.message}</div>
                    </div>
                    }
                    {loadingUpdateProduct && <div className='client-modal-inputs-row'>
                            <Spinner />
                        </div>
                    }
                    <div className='client-modal-buttons'>
                        <button type='submit' onClick={editarCategoryNameProducto} className='client-modal-buttons-blue' disabled={disabledButtonEditCategoryName}>Guardar</button>
                        <button type='reset' onClick={cerrarModalEditarCategoryName} className='client-modal-buttons-red'>Cancelar</button>
                    </div>
                </form>
            </div>
        </Modal>
        <Modal
            show={showDeleteImage}
            setShow={cerrarModalBorrarImagen}
        >
            <div className='client-modal-children'>
                <div className='client-modal-title'>Se eliminará la imágen del producto <span>{product.name}</span>, ¿estás seguro?</div>
                <div className='col-12 product-itemImg-box mt-3 d-flex justify-content-center align-items-center'>
                    <img src={product.image_url} alt={product.name} />
                </div>
                {uploadImageProductErrors.length > 0 && <div className='login-page-errors'>
                    {uploadImageProductErrors.map((error, i) => (
                        <div key={i}>{error.message}</div>
                    ))}
                    </div>
                }
                {uploadImageProductErrors.message && <div className='login-page-errors'>
                    <div>{uploadImageProductErrors.message}</div>
                </div>
                }
                {loadingUploadImageProduct && <div className='client-modal-inputs-row'>
                        <Spinner />
                    </div>
                }
                <div className='client-modal-buttons'>
                    <button onClick={borrarImagenProducto} className='client-modal-buttons-blue'>Confirmar</button>
                    <button onClick={cerrarModalBorrarImagen} className='client-modal-buttons-red'>Cancelar</button>
                </div>
            </div>
        </Modal>
        <Modal
            show={showUploadImage}
            setShow={cerrarModalSubirImagen}
        >
            <div className='client-modal-children'>
                <div className='client-modal-title fw-bold'>Subir imágen del producto <span>{product.name}</span></div>
                <form className='client-modal-inputs' onSubmit={subirImagenProducto}>
                    <div className='col-12 client-modal-inputs-row'>
                        <label className='col-3 text-center'>Imágen</label>
                        <input
                            id='product-image-input'
                            className='col-9' type='file'
                            {...register4('product_image', {required: true})}
                            onChange={verificarBotonUploadImage}
                            accept=".jpg, .jpeg, .png, .webp"
                            style={{display: 'none'}}
                        />
                        <button
                            className='col-9 product-image-input-button' 
                            onClick={(e) => document.getElementById('product-image-input').click()}
                            type='button'
                        >
                            {valueUploadImage ? <MdCloudDone className='text-white me-2' size='20px' /> : <MdCloudUpload className='text-white me-2' size='20px'/>}
                            {valueUploadImage ? 'Imágen seleccionada' : 'Subir imágen'}
                        </button>
                    </div>
                    {valueUploadImage
                    ? <div className='col-12 client-modal-inputs-row product-image-input-rules-box'>
                        <div className='col-12 col-sm-9 product-image-input-rules'>
                            Tamaño máximo: 2MB<br/>
                            Tipos de archivos permitidos: .jpg, .jpeg, .png, .webp
                        </div>
                        {valueUploadImage && <div className='col-12 col-sm-3 product-image-input-rules product-itemImg-box'>
                            <img src={URL.createObjectURL(valueUploadImage)} alt='imagen seleccionada' />
                        </div>}
                    </div>
                    : <div className='col-12 col-sm-9 offset-sm-3 client-modal-inputs-row'>
                        <div className='col-12 product-image-input-rules'>
                            Tamaño máximo: 2MB<br/>
                            Tipos de archivos permitidos: .jpg, .jpeg, .png, .webp
                        </div>
                    </div>
                    }
                    {errors4.image && <p className='client-modal-inputs-errors'>La imágen es requerida {errors4.image}</p>}
                    {uploadImageProductErrors.length > 0 && <div className='login-page-errors'>
                        {uploadImageProductErrors.map((error, i) => (
                            <div key={i}>{error.message}</div>
                        ))}
                        </div>
                    }
                    {uploadImageProductErrors.message && <div className='login-page-errors'>
                        <div>{uploadImageProductErrors.message}</div>
                    </div>
                    }
                    {loadingUploadImageProduct && <div className='client-modal-inputs-row'>
                            <Spinner />
                        </div>
                    }
                    <div className='client-modal-buttons'>
                        <button type='submit' onClick={subirImagenProducto} className='client-modal-buttons-blue' disabled={disabledButtonSubirImagen}>Guardar</button>
                        <button type='reset' onClick={cerrarModalSubirImagen} className='client-modal-buttons-red'>Cancelar</button>
                    </div>
                </form>
            </div>
        </Modal>
        </>
)
}

export default Item;