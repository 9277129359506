import axios from './axios.js'

export const getPrivateProductsRequest = () => axios.get(`/products`)

export const createProductRequest = (product) => axios.post('/products', product)

export const deleteProductRequest = (id) => axios.delete(`/products/${id}`)

export const updateNameProductRequest = (id, name) => axios.put(`/products/name/${id}`, name)

export const updateDescriptionProductRequest = (id, description) => axios.put(`/products/description/${id}`, description)

export const updatePriceProductRequest = (id, price) => axios.put(`/products/price/${id}`, price)

export const updateActiveProductRequest = (id) => axios.put(`/products/active/${id}`)

export const updateHasStockProductRequest = (id) => axios.put(`/products/has_stock/${id}`)

export const updateCategoryProductRequest = (id, category) => axios.put(`/products/category/${id}`, category)

export const uploadImageProductRequest = (id, image) => axios.post(`/products/uploads/${id}`, image)

export const deleteImageProductRequest = (id) => axios.delete(`/products/uploads/${id}`)