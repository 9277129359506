import { useEffect, useState, createContext, useContext } from "react";
import { getPrivateProductsRequest, createProductRequest, deleteProductRequest, updateNameProductRequest, updateDescriptionProductRequest, updatePriceProductRequest, updateActiveProductRequest, updateHasStockProductRequest, updateCategoryProductRequest, uploadImageProductRequest, deleteImageProductRequest } from '../api/product.js'

const ProductContext = createContext()

export const useProducts = () => {
    const context = useContext(ProductContext)
    if(!context) throw new Error("useProducts must be used within an TaskProvider")

    return context
}

export const ProductProvider = ({children}) => {
    const [products, setProducts] = useState([])
    const [loadingProducts, setLoadingProducts] = useState(true)
    const [createProductErrors, setCreateProductErrors] = useState([])
    const [deleteProductErrors, setDeleteProductErrors] = useState([])
    const [updateProductErrors, setUpdateProductErrors] = useState([])
    const [uploadImageProductErrors, setUploadImageProductErrors] = useState([])
    const [loadingCreateProduct, setLoadingCreateProduct] = useState(false)
    const [loadingDeleteProduct, setLoadingDeleteProduct] = useState(false)
    const [loadingUpdateProduct, setLoadingUpdateProduct] = useState(false)
    const [loadingUploadImageProduct, setLoadingUploadImageProduct] = useState(false)

    useEffect(() => {
        if(createProductErrors.length > 0 || createProductErrors.message) {
            const timer = setTimeout(() => {
                setCreateProductErrors([])
            }, 5000)
            return () => clearTimeout(timer)
        }
    }, [createProductErrors])

    useEffect(() => {
        if(deleteProductErrors.length > 0 || deleteProductErrors.message) {
            const timer = setTimeout(() => {
                setDeleteProductErrors([])
            }, 5000)
            return () => clearTimeout(timer)
        }
    }, [deleteProductErrors])

    useEffect(() => {
        if(updateProductErrors.length > 0 || updateProductErrors.message) {
            const timer = setTimeout(() => {
                setUpdateProductErrors([])
            }, 5000)
            return () => clearTimeout(timer)
        }
    }, [updateProductErrors])

    useEffect(() => {
        if(uploadImageProductErrors.length > 0 || uploadImageProductErrors.message) {
            const timer = setTimeout(() => {
                setUploadImageProductErrors([])
            }, 5000)
            return () => clearTimeout(timer)
        }
    }, [uploadImageProductErrors])

    const updateCategoryNameInProducts = (name, newName) => {
        const productsUpdated = products.map((product) => {
            if(product.category_name === name) {
                product.category_name = newName
                return product
            }
            return product
        })
        ordenarLista(productsUpdated)
    }

    const ordenarLista = (products) => {
        const sortedList = [...products].sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 0))
        setProducts(sortedList)
    }

    const getProducts = async (username) => {
        try {
            const res = await getPrivateProductsRequest(username)
            const products = res.data
            ordenarLista(products)
            setLoadingProducts(false)
        } catch(error) {
            console.error(error)
        }
    }

    const createProduct = async (product) => {
        setLoadingCreateProduct(true)
        try {
            const res = await createProductRequest(product)
            const newProducts = [...products, res.data]
            ordenarLista(newProducts)
            setLoadingCreateProduct(false)
            return true
        } catch(error) {
            if(error.code === "ERR_NETWORK") {
                setCreateProductErrors([{message: error.message}])
            } else {
                setCreateProductErrors(error.response.data)
            }
            setLoadingCreateProduct(false)
            return false
        }
    }

    const deleteProduct = async (id) => {
        setLoadingDeleteProduct(true)
        try {
            const res = await deleteProductRequest(id)
            const productsUpdated = products.filter(product => product.id != id)
            ordenarLista(productsUpdated)
            setLoadingDeleteProduct(false)
            return true
        } catch(error) {
            if(error.code === "ERR_NETWORK") {
                setDeleteProductErrors([{message: error.message}])
            } else {
                setDeleteProductErrors(error.response.data)
            }
            setLoadingDeleteProduct(false)
            return false
        }
    }

    const updateNameProduct = async (id, name) => {
        setLoadingUpdateProduct(true)
        try {
            const res = await updateNameProductRequest(id, name)
            const productsUpdated = products.map(product => {
                if(product.id === id) return product = res.data
                return product
            })
            ordenarLista(productsUpdated)
            setLoadingUpdateProduct(false)
            return true
        } catch(error) {
            if(error.code === "ERR_NETWORK") {
                setUpdateProductErrors([{message: error.message}])
            } else {
                setUpdateProductErrors(error.response.data)
            }
            setLoadingUpdateProduct(false)
            return false
        }
    }

    const updateDescriptionProduct = async (id, description) => {
        setLoadingUpdateProduct(true)
        try {
            const res = await updateDescriptionProductRequest(id, description)
            const productsUpdated = products.map(product => {
                if(product.id === id) return product = res.data
                return product
            })
            ordenarLista(productsUpdated)
            setLoadingUpdateProduct(false)
            return true
        } catch(error) {
            if(error.code === "ERR_NETWORK") {
                setUpdateProductErrors([{message: error.message}])
            } else {
                setUpdateProductErrors(error.response.data)
            }
            setLoadingUpdateProduct(false)
            return false
        }
    }

    const updatePriceProduct = async (id, price) => {
        setLoadingUpdateProduct(true)
        try {
            const res = await updatePriceProductRequest(id, price)
            const productsUpdated = products.map(product => {
                if(product.id === id) return product = res.data
                return product
            })
            ordenarLista(productsUpdated)
            setLoadingUpdateProduct(false)
            return true
        } catch(error) {
            if(error.code === "ERR_NETWORK") {
                setUpdateProductErrors([{message: error.message}])
            } else {
                setUpdateProductErrors(error.response.data)
            }
            setLoadingUpdateProduct(false)
            return false
        }
    }

    const updateActiveProduct = async (id) => {
        setLoadingUpdateProduct(true)
        try {
            const res = await updateActiveProductRequest(id)
            const productsUpdated = products.map(product => {
                if(product.id === id) return product = res.data
                return product
            })
            ordenarLista(productsUpdated)
            setLoadingUpdateProduct(false)
            return true
        } catch(error) {
            if(error.code === "ERR_NETWORK") {
                setUpdateProductErrors([{message: error.message}])
            } else {
                setUpdateProductErrors(error.response.data)
            }
            setLoadingUpdateProduct(false)
            return false
        }
    }

    const updateHasStockProduct = async (id) => {
        setLoadingUpdateProduct(true)
        try {
            const res = await updateHasStockProductRequest(id)
            const productsUpdated = products.map(product => {
                if(product.id === id) return product = res.data
                return product
            })
            ordenarLista(productsUpdated)
            setLoadingUpdateProduct(false)
            return true
        } catch(error) {
            if(error.code === "ERR_NETWORK") {
                setUpdateProductErrors([{message: error.message}])
            } else {
                setUpdateProductErrors(error.response.data)
            }
            setLoadingUpdateProduct(false)
            return false
        }
    }

    const updateCategoryProduct = async (id, category) => {
        setLoadingUpdateProduct(true)
        try {
            const res = await updateCategoryProductRequest(id, category)
            const productsUpdated = products.map(product => {
                if(product.id === id) return product = res.data
                return product
            })
            ordenarLista(productsUpdated)
            setLoadingUpdateProduct(false)
            return true
        } catch(error) {
            if(error.code === "ERR_NETWORK") {
                setUpdateProductErrors([{message: error.message}])
            } else {
                setUpdateProductErrors(error.response.data)
            }
            setLoadingUpdateProduct(false)
            return false
        }
    }

    const uploadImageProduct = async (id, image) => {
        setLoadingUploadImageProduct(true)
        try {
            const res = await uploadImageProductRequest(id, image)
            const productsUpdated = products.map(product => {
                if(product.id === id) return product = res.data
                return product
            })
            ordenarLista(productsUpdated)
            setLoadingUploadImageProduct(false)
            return true
        } catch(error) {
            if(error.code === "ERR_NETWORK") {
                setUploadImageProductErrors([{message: error.message}])
            } else {
                setUploadImageProductErrors(error.response.data)
            }
            setLoadingUploadImageProduct(false)
            return false
        }
    }

    const deleteImageProduct = async (id) => {
        setLoadingUploadImageProduct(true)
        try {
            const res = await deleteImageProductRequest(id)
            const productsUpdated = products.map(product => {
                if(product.id === id) return product = res.data
                return product
            })
            ordenarLista(productsUpdated)
            setLoadingUploadImageProduct(false)
            return true
        } catch(error) {
            if(error.code === "ERR_NETWORK") {
                setUploadImageProductErrors([{message: error.message}])
            } else {
                setUploadImageProductErrors(error.response.data)
            }
            setLoadingUploadImageProduct(false)
            return false
        }
    }

    return(
        <ProductContext.Provider value={{products, loadingProducts, createProductErrors, deleteProductErrors, updateProductErrors, uploadImageProductErrors, loadingCreateProduct, loadingDeleteProduct, loadingUpdateProduct, loadingUploadImageProduct, getProducts, createProduct, deleteProduct, updateNameProduct, updateDescriptionProduct, updatePriceProduct, updateActiveProduct, updateHasStockProduct, updateCategoryProduct, uploadImageProduct, deleteImageProduct, setCreateProductErrors, setDeleteProductErrors, setUpdateProductErrors, setUploadImageProductErrors, updateCategoryNameInProducts}}>
            {children}
        </ProductContext.Provider>
    )
}