import './Category.css'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Spinner } from 'reactstrap';
import { useCategories } from '../../context/CategoryContext.js'
import Modal from '../Modal/Modal.js'
import { MdEdit, MdDelete } from "react-icons/md";

function Category({category}) {
    const {deleteCategory, deleteCategoryErrors, setDeleteCategoryErrors, loadingDeleteCategory, updateCategoryErrors, setUpdateCategoryErrors, loadingUpdateCategory, updateNameCategory} = useCategories()
    const {register, handleSubmit, formState: {errors}, reset} = useForm()
    // const {register: register1, handleSubmit: handleSubmit1, formState: {errors: errors1}, reset: reset1} = useForm()
    const [showEdit, setShowEdit] = useState(false)
    const [showDelete, setShowDelete] = useState(false)

    const [showEditName, setShowEditName] = useState(false)
    const [disabledButtonEditName, setDisabledButtonEditName] = useState(true)
    const [valueEditName, setValueEditName] = useState(category.name)

    // ------------------------------------------------------------------------------------------------

    const abrirModalEditar = () => setShowEdit(true)

    const abrirModalBorrar = () => setShowDelete(true)

    const cerrarModalEditar = () => setShowEdit(false)

    const cerrarModalBorrar = () => {
        setShowDelete(false)
        setDeleteCategoryErrors([])
    }

    const borrarCategoría = async () => {
        if(await deleteCategory(category.name)) {
            cerrarModalBorrar()
        }
    }

    // NAME----------------------------------------------------------------------------------------

    const resetForm = () => {
        reset()
        setUpdateCategoryErrors([])
    }

    const abrirModalEditarName = () => {
        cerrarModalEditar()
        setValueEditName(category.name)
        setShowEditName(true)
    }

    const cerrarModalEditarName = () => {
        setShowEditName(false)
        resetForm()
        setDisabledButtonEditName(true)
    }

    const editarNameCategory = handleSubmit(async (values) => {
        values.name = valueEditName
        if (values.name.trim() === '') {
            setUpdateCategoryErrors([{message: 'El nombre no puede ser solo espacios en blanco'}]);
            return;
        }
        if(await updateNameCategory(category.name, values)) {
            cerrarModalEditarName()
        }
    })

    const verificarBotonEditName = (e) => {
        if(e.target.value === category.name) {
            setDisabledButtonEditName(true)
        } else {
            setDisabledButtonEditName(false)
        }
        setValueEditName(e.target.value)
    }

    return (
        <div className='categoryButton'>
            <div className='text-center text-break ps-1 pe-2 text-white' style={{lineHeight: '16px', flex: '1'}}>{category.name}</div>
            <div className='d-flex'>
                <MdEdit className='me-1 text-white' size='20px' onClick={abrirModalEditar} />
                <MdDelete className='text-white' size='20px' onClick={abrirModalBorrar} />
            </div>
            <Modal
                show={showEdit}
                setShow={setShowEdit}
            >
                <div className='client-modal-children'>
                    <div className='client-modal-title fw-bold'>Editar categoría <span>{category.name}</span></div>
                    <div className='col-12 client-modal-edit'>
                        <button className='col-12' onClick={abrirModalEditarName}>Editar nombre</button>
                    </div>
                    <div className='client-modal-buttons'>
                        <button onClick={cerrarModalEditar} className='client-modal-buttons-red'>Cancelar</button>
                    </div>
                </div>
            </Modal>
            <Modal
                show={showDelete}
                setShow={cerrarModalBorrar}
            >
                <div className='client-modal-children'>
                    <div className='client-modal-title'>Se eliminará la categoría <span>{category.name}</span>, ¿estás seguro?</div>
                    {deleteCategoryErrors.length > 0 && <div className='login-page-errors'>
                        {deleteCategoryErrors.map((error, i) => (
                            <div key={i}>{error.message}</div>
                        ))}
                        </div>
                    }
                    {deleteCategoryErrors.message && <div className='login-page-errors'>
                        <div>{deleteCategoryErrors.message}</div>
                    </div>
                    }
                    {loadingDeleteCategory && <div className='client-modal-inputs-row'>
                            <Spinner />
                        </div>
                    }
                    <div className='client-modal-buttons'>
                        <button onClick={borrarCategoría} className='client-modal-buttons-red'>Eliminar</button>
                        <button onClick={cerrarModalBorrar} className='client-modal-buttons-blue'>Cancelar</button>
                    </div>
                </div>
            </Modal>
            <Modal
                show={showEditName}
                setShow={cerrarModalEditarName}
            >
                <div className='client-modal-children'>
                    <div className='client-modal-title fw-bold'>Cambiar el nombre de <span>{category.name}</span></div>
                    <form className='client-modal-inputs' onSubmit={editarNameCategory}>
                        <div className='col-12 client-modal-inputs-row'>
                            <label className='col-3 text-center'>Nombre</label>
                            <input
                                className='col-9' type='text' placeholder='Nombre nuevo...'
                                {...register('name', {required: true})}
                                maxLength={20}
                                onChange={verificarBotonEditName}
                                value={valueEditName}
                            />
                        </div>
                        {errors.name && <p className='client-modal-inputs-errors'>El nombre nuevo es requerido</p>}
                        {updateCategoryErrors.length > 0 && <div className='login-page-errors'>
                            {updateCategoryErrors.map((error, i) => (
                                <div key={i}>{error.message}</div>
                            ))}
                            </div>
                        }
                        {updateCategoryErrors.message && <div className='login-page-errors'>
                            <div>{updateCategoryErrors.message}</div>
                        </div>
                        }
                        {loadingUpdateCategory && <div className='client-modal-inputs-row'>
                                <Spinner />
                            </div>
                        }
                        <div className='client-modal-buttons'>
                            <button type='submit' onClick={editarNameCategory} className='client-modal-buttons-blue' disabled={disabledButtonEditName}>Guardar</button>
                            <button type='reset' onClick={cerrarModalEditarName} className='client-modal-buttons-red'>Cancelar</button>
                        </div>
                    </form>
                </div>
            </Modal>
        </div>
    )
}

export default Category