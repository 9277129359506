import './App.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { AuthProvider } from './context/AuthContext.js';
import { ClientProvider } from './context/ClientContext.js';
import { ProductProvider } from './context/ProductContext.js';
import { CategoryProvider } from './context/CategoryContext.js';
import ProtectedRoute from './ProtectedRoute.js';
import LoginPage from './pages/LoginPage.js';
import DashBoardPage from './pages/DashBoardPage.js';
import NotFoundPage from './NotFoundPage.js';
import HomePage from './pages/HomePage.js';

const QrMenu = () => {
  return (
    <AuthProvider>
        <ClientProvider>
          <ProductProvider>
            <CategoryProvider>
              <BrowserRouter basename='/'>
                  <Routes>
                      <Route path="/login" element={<LoginPage />} />
                      <Route path="*" element={<NotFoundPage />} />

                      <Route element={<ProtectedRoute />}>
                        <Route path="/" element={<HomePage />} />
                      </Route>
                  </Routes>
              </BrowserRouter>
            </CategoryProvider>
          </ProductProvider>
        </ClientProvider>
    </AuthProvider>
  );
}

export default QrMenu;