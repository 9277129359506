import { useEffect } from 'react';
import './Modal.css'

const Modal = ({children, show, setShow}) => {
    useEffect(() => {
        window.onkeydown = (e) => {
            if (e.keyCode === 27 && show) {
                setShow(false)
            }
        }
    }, [show]) 

    return (
        <>
            {show && <div className="client-modal">
                <div className='client-modal-container'>
                        {children}
                </div>
            </div>
            }
        </>
    )
}

export default Modal